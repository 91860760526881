import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCgu } from '#reducers/sessionReducer';
// Images
import { home_big_picto } from "#images";
// Components
import Button from '#components/global/Button';
import LargeHeader from '#components/header/LargeHeader';
import LayoutBackground from "#layouts/LayoutBackground";
import Footer from '#components/footer/Footer'
// Styles
import styles from "./Home.module.scss";

const Home = () => {
  const [maintenance, setMaintenance] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fonction pour naviguer vers l'enregistrement
  const navigateToRegistration = () => {
    dispatch(setCgu());
    navigate('/record');
  };

  useEffect(() => {
    if(window.REACT_APP_MAINTENANCE_MODE !== undefined) {
      setMaintenance(!!window.REACT_APP_MAINTENANCE_MODE);
    }
  }, []);

  return (
    <LayoutBackground pageType="Home">
      <LargeHeader />
      <div className={styles.appContainer}>
        <div className={styles.content}>
          <h1>Bienvenue sur <br />Unilabs express<span className='text-alert'>.</span></h1>
          <div className={styles.textHome}>
            Enregistrez-vous en ligne et gagnez du temps lors de votre visite au laboratoire.
          </div>
          {maintenance ? (
            <h2 className={styles.maintenanceMode}>Le service est actuellement indisponible</h2>
            ) : (
              <div>
              <Button 
                className='homeButtonPrimary'
                text="JE M'ENREGISTRE" 
                onClick={navigateToRegistration}
                />
            </div>
          )}
        </div>
        <div className={styles.picto}>
          <img className={styles.pictoCard} src={home_big_picto} alt="Le pass logo"/>
        </div>
      </div>
      <Footer className={styles.footerHome}/>
    </LayoutBackground>
  );
};

export default Home;