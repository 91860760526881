import { horizontalLogoPath } from "#images"
// Styles
import styles from "./LargeHeader.module.scss";

const LargeHeader = () => {
  return (
    <header className={styles.header}>
      <img
        src={horizontalLogoPath}
        alt="Brand Logo Horizontal"
      />
    </header>
  )
}

export default LargeHeader